import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
// Danger, Success
export function showToast(text, type) {
  createToast(text, {
    type: type,
    transition: "bounce",
    position: "top-right",
    // hideProgressBar: true,
    showIcon: true,
  });
}


export function formatNumber(num) {
  num = roundTwoDecimals(parseFloat(num));
  num = num + "";
  num = num.replace(".", ",");
  num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return num;
}
export const roundTwoDecimals = (num) => {
  return parseFloat((Math.round(num * 100) / 100).toFixed(2));
};